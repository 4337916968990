export default function MobileGraphic() {
	return (
		<div>
			<div className='flex center pdt'>
				<img
					src='https://firebasestorage.googleapis.com/v0/b/gbapp-b859e.appspot.com/o/ps_graphic-min.png?alt=media&token=48575232-80d8-40c9-8a1a-e5f15c18151b'
					alt='download graphic'
					className='graphic_phones'
				/>
			</div>
			<div className='flex center pdt'>
				<a
					href='https://play.google.com/store/apps/details?id=com.goodbyeapp.twa'
					target='_blank'
					rel='noreferrer'
					tabIndex='0'
				>
					<img
						src='https://upload.wikimedia.org/wikipedia/commons/thumb/7/78/Google_Play_Store_badge_EN.svg/2560px-Google_Play_Store_badge_EN.svg.png'
						width={190}
						alt='Play Store'
					/>
				</a>
			</div>
		</div>
	);
}
