import {
	Accordion,
	AccordionDetails,
	AccordionSummary,
	Avatar,
	Typography,
} from '@mui/material';
import Footer from '../../components/Footer/Footer';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import HelpIcon from '@mui/icons-material/Help';
import CustomAccordion from '../../components/SocialComponents/CustomAccordion';
import { Link } from 'react-router-dom';
import CustomButton from '../../components/SocialComponents/CustomButton';
export default function FAQ() {
	const { t } = useTranslation();
	const mode = useSelector((state) => state.UI.mode);

	return (
		<div>
			<div className='skyBackground'>
				<div className='pdAccordion'>
					<div className={`card pdt ${mode === 'dark' && 'dbc'}`}>
						<Avatar id='cardMainIcon2' className='flexAuto'>
							<HelpIcon id='cardMainIconSize2' />
						</Avatar>
						<h1 className='subtitle bold center mgYH'>
							Frequently Asked Questions
						</h1>
						<CustomAccordion
							// expanded={true}
							title='What is Goodbye App?'
							text='Goodbye App is a unique farewell social media platform that
									allows users to schedule posts and letters up to 30 years in
									the future for their loved ones.'
						/>
						<CustomAccordion
							title='What broadcasting features does Goodbye App offer?'
							text='Goodbye App allows users to upload and share videos, audio messages, and digital content with specific recipients or the general public. These broadcasts can be shared wirelessly over the internet and our social media app.'
						/>
						<CustomAccordion
							title='Can Goodbye App deliver messages to specific recipients?'
							text=' Yes, users can schedule content delivery to specific recipients, ensuring that everything is shared at the right time.'
						/>
						<CustomAccordion
							title='What is the “Release Earlier Protocol”'
							text='The Release Earlier Protocol is a feature that automatically releases unreleased content if a user becomes inactive for a set period. It ensures that important messages are shared even if the user cannot do so themselves.'
						/>
						<CustomAccordion
							title='Does Goodbye App provide multilingual support?'
							text='Yes, Goodbye App currently supports 10 languages, including English, Spanish, French, Japanese, and Arabic, with plans to expand further. Language preferences can be changed in the top right settings panel.'
						/>
						<CustomAccordion
							title='How does Goodbye App ensure safe storage of digital content?'
							text='Goodbye App provides secure cloud-based storage for all uploaded content. Users can access their content anytime and choose when it should be delivered.'
						/>
						<CustomAccordion
							title='Can businesses use it?'
							text='Absolutely. Goodbye App offers businesses tools to promote their services and
									broadcast meaningful content to their audience via the seamless electronic transmission of videos and files across different devices and platforms, including email and social media.'
						/>
						<CustomAccordion
							title='How does Goodbye App help businesses promote their services?'
							text='Goodbye App provides businesses with the ability to create accounts, upload promotional content, and share it with targeted audiences.'
						/>
						<CustomAccordion
							title='Can I use Goodbye App for business networking?'
							text='Yes, Goodbye App supports online business networking by allowing businesses and professionals to connect, share updates, and build meaningful relationships with their audience.'
						/>
						<CustomAccordion
							title='Does Goodbye App provide Business Administration services?'
							text='Yes. Goodbye App provides tools designed to help businesses with administrative functions, such as:'
							component={
								<ul className='smallText'>
									<li>
										<b>Content Scheduling:</b> Businesses can plan and schedule
										their promotional messages in advance.
									</li>
									<li>
										<b>Audience Organization:</b> Businesses can target
										audiences or user groups with their content.
									</li>
									<li>
										<b>Analytics:</b> Goodbye App provides notifications on how
										uploaded content is performing, helping businesses track and
										manage user engagement.
									</li>
									<li>
										<b>File Management:</b> The platform allows businesses to
										upload and organize media files, ensuring their content is
										ready for broadcasting or sharing.
									</li>
								</ul>
							}
						/>
						<CustomAccordion
							title='Does Goodbye App provide Business Management services?'
							text='Definitely. Goodbye App offers a suite of tools to help businesses manage their promotional and marketing efforts, including:'
							component={
								<ul className='smallText'>
									<li>
										<b>Campaign Management:</b> Businesses can create and
										oversee promotional campaigns using custom messages and
										videos.
									</li>
									<li>
										<b>Audience Engagement: </b> Businesses can interact with
										users, share personalized content, and monitor their impact
										on the audience.
									</li>
									<li>
										<b>Account Management:</b> Accounts on Goodbye App can
										organize their activities, manage their online presence, and
										update their branding.
									</li>
								</ul>
							}
						/>
						<CustomAccordion
							title='How to get started with Goodbye App?'
							text={
								<span>
									Simply sign up on our website or app, create your profile, and
									start uploading your content. Our user-friendly interface
									ensures a seamless experience for individuals and businesses
									alike.
								</span>
							}
							component={
								<Link to='/signup'>
									<CustomButton
										variant='contained'
										startIcon={<AccountCircleIcon />}
										btnText={t('signup')}
										id='customInfoButton'
									/>
								</Link>
							}
						/>
					</div>
				</div>
			</div>
			<Footer />
		</div>
	);
}
