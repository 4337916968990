import { Button, CircularProgress, IconButton } from '@mui/material';
import CustomTooltip from './CustomTooltip';
import Media from 'react-media';

const CustomButton = ({
	type,
	fullWidth,
	variant,
	disabled,
	loading,
	btnText,
	id,
	startIcon,
	endIcon,
	onClick,
	iconButton,
	ariaLabel,
	fontSize,
	margin,
	padding,
	borderRadius,
	tooltip,
	off,
	textTransform,
	disableRipple,
	className,
	height,
	color,
	placement,
	ariaDescribedby,
	onlyIconBtn,
}) => {
	return iconButton ? (
		<CustomTooltip
			title={disabled ? '' : tooltip ? tooltip : ''}
			arrow
			placement={placement ? placement : 'bottom'}
		>
			<IconButton
				aria-label={ariaLabel}
				aria-describedby={ariaDescribedby}
				id={id}
				onClick={onClick}
				disableRipple={disableRipple}
				color={color ? color : 'primary'}
				className={className}
				style={{
					margin: margin ? margin : '',
					padding: padding ? padding : '',
				}}
				disabled={loading || off || disabled}
			>
				{startIcon}
			</IconButton>
		</CustomTooltip>
	) : (
		<Media
			queries={{
				largerScreen: '(min-width: 1100px)',
				fontChange: '(max-width: 905px)',
			}}
		>
			{(matches) => (
				<CustomTooltip
					title={loading || off || disabled ? '' : tooltip ? tooltip : ''}
					placement={placement ? placement : 'bottom'}
					arrow
				>
					<Button
						aria-describedby={ariaDescribedby}
						onClick={onClick}
						type={type}
						fullWidth={fullWidth}
						variant={variant}
						id={id}
						disableRipple={disableRipple}
						className={className ? className : ''}
						disabled={(loading && true) || off || disabled}
						startIcon={btnText && startIcon ? startIcon : null}
						endIcon={endIcon}
						color={color ? color : 'primary'}
						style={{
							fontSize: fontSize
								? fontSize
								: matches.largerScreen
								? '18px'
								: '15px',
							textTransform: textTransform ? textTransform : 'uppercase',
							margin: margin ? margin : '1rem auto',
							padding: padding && padding,
							borderRadius: borderRadius ? borderRadius : '30px',
							backgroundColor: disableRipple && 'transparent',
							height: height ? height : '',
							fontFamily: 'system-ui',
						}}
						sx={{
							'&:disabled': {
								pointerEvents: 'all !important',
								cursor: 'not-allowed',
							},
						}}
					>
						<div className='flex'>
							{loading ? (
								<CircularProgress
									variant='indeterminate'
									id='white'
									size={matches.fontChange || onlyIconBtn ? 20.5 : 21.5}
									thickness={3}
									style={{
										margin: onlyIconBtn
											? '5.7px 0'
											: matches.fontChange
											? '3px'
											: '5px',
									}}
								/>
							) : onlyIconBtn ? (
								onlyIconBtn
							) : btnText ? (
								btnText
							) : (
								startIcon
							)}
						</div>
					</Button>
				</CustomTooltip>
			)}
		</Media>
	);
};

export default CustomButton;
