import { Component } from 'react';
import { Helmet } from 'react-helmet-async';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Footer from '../../components/Footer/Footer';
import { withRouter } from '../../redux/withRouter';
import { Avatar, Divider } from '@mui/material';
import Diversity1OutlinedIcon from '@mui/icons-material/Diversity1Outlined';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import CustomAlerts from '../../components/SocialComponents/CustomAlerts';
import donateImg from '../../css/images/donateBtn.png';
import donateQRcode from '../../css/images/donateQRcode.png';
import { launchConfetti } from '../../redux/actions/dataActions';
import PaymentIcon from '@mui/icons-material/Payment';
import { Link } from 'react-router-dom';
// import { toast } from 'react-toastify';
import { t } from 'i18next';
class Donate extends Component {
	state = {
		received: false,
	};
	// loadDonation = () => {
	// 	toast.loading(t('redirecting'), {
	// 		position: 'bottom-left',
	// 		autoClose: 3000,
	// 		hideProgressBar: false,
	// 		closeOnClick: true,
	// 		pauseOnHover: true,
	// 		draggable: true,
	// 		progress: undefined,
	// 		containerId: 'app',
	// 	});
	// };

	componentDidMount() {
		if (this.props.params.received) {
			this.setState({
				received: true,
			});
			launchConfetti(2.5);
		}
	}

	render() {
		const { received } = this.state;
		const {
			credentials: { handle, name },
			mode,
		} = this.props;

		const user = name ? name : handle ? handle : 'User';
		const username = user.charAt(0).toUpperCase() + user.slice(1);
		return (
			<div>
				<Helmet>
					<title>Donations - Goodbye App</title>
				</Helmet>

				<div className='skyBackground'>
					<CustomAlerts
						icon={<PaymentIcon />}
						success={received}
						message={t('donation_success')}
						margin='2rem 0 0'
						noBorder
					/>
					<div className='pd2Media'>
						<div className={`card pd2b1Media ${mode === 'dark' && 'dbc'}`}>
							<Avatar id='cardMainIcon' className='flexAuto'>
								<Diversity1OutlinedIcon id='cardMainIconSize' />
							</Avatar>
							<h1 className='title mg0 center'>{t('donate')}</h1>
							<h3 className='subtitle center mg0'>{t('donate_subtitle')}</h3>
							<p className='bold'>{t('donate_intro', { handle: username })}</p>
							<p>{t('donate_desc_1')}</p>
							<p>{t('donate_desc_2')}</p>

							<CustomAlerts
								icon={<AccountCircleIcon />}
								info={t('donate_desc_3')}
								margin='0'
							/>
							<p>{t('donate_desc_4')}</p>
							<p>{t('donate_desc_5')}</p>
							<p className='mg0'>
								{t('donate_desc_6')}{' '}
								<Link
									to='/terms-of-service'
									className='new underlineHover pointer'
								>
									{t('terms_of_service')},
								</Link>{' '}
								{t('and')}{' '}
								<Link
									to='/privacy-policy'
									className='new underlineHover pointer'
								>
									{t('privacy_policy')}
								</Link>
								.
							</p>
							<CustomAlerts
								icon={<FavoriteBorderIcon />}
								info={<div className='bold'>{t('donate_desc_7')}</div>}
								severity='secondary'
								margin='1rem 0 0 0'
							/>
							<div
								className='flex center mgt'
								// onClick={this.loadDonation}
							>
								<form
									action='https://www.paypal.com/donate'
									method='post'
									// target='_top'
									target='_blank'
								>
									<input
										type='hidden'
										name='hosted_button_id'
										value='DMCFUVFN7SHCQ'
									/>
									<input
										type='image'
										src={donateImg}
										border='0'
										name='submit'
										title={t('paypal_safe')}
										alt={t('donate_with_paypal')}
										width={150}
									/>
									<img
										alt=''
										border='0'
										src='https://www.paypal.com/en_US/i/scr/pixel.gif'
										width='1'
										height='1'
									/>
								</form>
							</div>
							<div className='mgt  center'>
								<Divider className='text '>{t('donate_qr')}</Divider>
								<img
									className='mgt1H'
									src={donateQRcode}
									alt='donate qr code'
									width={150}
									height={150}
								/>
							</div>
							<p className='mgtOnly'>
								{t('donate_for_refunds')}{' '}
								<Link to='/refund-policy' id='link' className='bold'>
									{t('refund_policy')}
								</Link>
								.
							</p>
						</div>
					</div>
					<Footer />
				</div>
			</div>
		);
	}
}

const mapStateToProps = (state) => ({
	credentials: state.user.credentials,
	mode: state.UI.mode,
});
const mapActionsToProps = {};

Donate.propTypes = {
	credentials: PropTypes.object,
};

export default connect(mapStateToProps, mapActionsToProps)(withRouter(Donate));
