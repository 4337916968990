import {
	Avatar,
	Checkbox,
	FormControl,
	Grid,
	MenuItem,
	Select,
} from '@mui/material';
import PropTypes from 'prop-types';
import { Component } from 'react';
import { Helmet } from 'react-helmet-async';
import { support, clearReport } from '../../redux/actions/userActions';
import { connect } from 'react-redux';
import ReCAPTCHA from 'react-google-recaptcha-enterprise';
import SendIcon from '@mui/icons-material/Send';
import SecurityIcon from '@mui/icons-material/Security';
import { withRouter } from '../../redux/withRouter';
import ContactsIcon from '@mui/icons-material/Contacts';
import { dim, isEmail } from '../../redux/actions/dataActions';
import CustomAlerts from '../../components/SocialComponents/CustomAlerts';
import Footer from '../../components/Footer/Footer';
import CustomInput from '../../components/SocialComponents/CustomInput';
import CustomButton from '../../components/SocialComponents/CustomButton';
import { Link } from 'react-router-dom';
import SmoothScrollToTop from '../../components/ScrollToTop/SmoothScrollToTop';
import { t } from 'i18next';

class Contact extends Component {
	state = {
		topic: 'support',
		body: '',
		reportErrors: {},
		title: '',
		email: '',
		name: '',
		termsAgreed: false,
		termsNotAgreed: false,
		amount: '',
		transactionId: '',
	};

	componentDidMount() {
		if (this.props.user.authenticated && this.props.user.emailVerified) {
			let update = {
				email: this.props.user.credentials.email,
			};
			if (this.props.user.credentials.name) {
				update.name = this.props.user.credentials.name;
			}

			this.setState(update);
		}
	}
	componentWillUnmount() {
		if (
			this.props.user.reportingSupportSuccess ||
			this.props.user.reportingFeature
		) {
			this.props.clearReport();
		}
	}
	static getDerivedStateFromProps(nextProps, state) {
		if (
			nextProps.user.authenticated &&
			nextProps.user.emailVerified &&
			nextProps.user.credentials.email &&
			state.email !== nextProps.user.credentials.email
		) {
			let update = {
				email: nextProps.user.credentials.email,
			};
			if (nextProps.user.credentials.name) {
				update.name = nextProps.user.credentials.name;
			}
			return update;
		}
		return null;
	}

	focus = (id) => {
		document.getElementById(id).focus();
	};

	blur = (id) => {
		document.getElementById(id).blur();
	};

	testEmpty = () => {
		let state = this.state;

		if (state.email.trim() === '' && !this.props.user.credentials.email) {
			this.focus('email');
			this.setState({
				reportErrors: { email: t('email_error_enter_address') },
			});
		} else if (
			isEmail(state.email) === false &&
			!this.props.user.credentials.email
		) {
			this.focus('email');
			this.setState({
				reportErrors: { email: t('email_error_enter_valid_address') },
			});
		} else if (state.topic !== 'refunds' && state.title.trim() === '') {
			this.focus('title');

			this.setState({
				reportErrors: { title: t('please_enter_a_title') },
			});
		} else if (state.topic === 'refunds' && state.transactionId.trim() === '') {
			this.focus('transactionId');

			this.setState({
				reportErrors: { transactionId: t('please_enter_the_transaction_id') },
			});
		} else if (state.body.trim() === '') {
			this.focus('body');

			this.setState({
				reportErrors: { body: t('please_enter_a_description') },
			});
		} else if (state.topic === 'refunds' && state.amount.trim() === '') {
			this.focus('amount');

			this.setState({
				reportErrors: { amount: t('please_enter_the_refund_amount') },
			});
		} else if (!state.termsAgreed && !this.props.user.credentials.email) {
			this.setState({
				termsNotAgreed: true,
			});
		} else {
			this.setState({
				reportErrors: {},
			});
			return true;
		}
	};

	submit = (value) => {
		let state = this.state;
		const auth = this.props.user.authenticated && this.props.user.emailVerified;
		let formData = {
			responseToken: value,
			body: state.body.trim(),
			name: state.name.trim(),
			topic: state.topic,
		};
		if (!auth) {
			formData.reporterEmail = state.email.toLowerCase();
		}

		if (state.topic === 'refunds') {
			formData.transactionId = state.transactionId.trim();
			formData.amount = state.amount.trim();
		} else {
			formData.title = state.title.trim();
		}

		this.props.support(formData, auth);

		this.recaptcha.reset();
	};

	submitForm = (event) => {
		event.preventDefault();
		if (this.testEmpty()) {
			const auth =
				this.props.user.authenticated && this.props.user.emailVerified;

			if (auth) {
				this.submit();
			} else {
				this.recaptcha.execute();
			}
		} else {
			this.recaptcha.reset();
		}
	};
	handleTopicChange = (event) => {
		this.setState({ topic: event.target.value });
	};

	handleChange = (event) => {
		this.setState({
			[event.target.name]: event.target.value,
		});
		let state = this.state;
		if (
			(event.target.name === 'title' && state.reportErrors.title) ||
			(event.target.name === 'body' && state.reportErrors.body) ||
			(event.target.name === 'email' && state.reportErrors.email)
		) {
			this.setState({
				reportErrors: {},
			});
		}
	};

	agreeTerms = () => {
		if (this.state.termsNotAgreed) {
			this.setState({
				termsNotAgreed: false,
			});
		}
		this.setState((prevState) => ({ termsAgreed: !prevState.termsAgreed }));
	};
	render() {
		const {
			topic,
			body,
			reportErrors,
			title,
			name,
			email,
			termsAgreed,
			termsNotAgreed,
			transactionId,
			amount,
		} = this.state;
		const {
			user: {
				authenticated,
				emailVerified,
				reportingSupport,
				reportingSupportSuccess,
				errorReportingSupport,
				credentials,
			},
			UI: { mode },
		} = this.props;

		const loader = reportingSupport;
		const success = reportingSupportSuccess;
		const error = errorReportingSupport;

		return (
			<div>
				<Helmet>
					<title>Contact - Goodbye App</title>
				</Helmet>
				<ReCAPTCHA
					sitekey={process.env.REACT_APP_CAPTCHA_SITE_KEY}
					ref={(ref) => (this.recaptcha = ref)}
					theme='dark'
					onChange={this.submit}
					size='invisible'
				/>

				<div id='backgroundAuth' className='pd2Media'>
					<div
						className={`card pd2b1Media ${mode === 'dark' && 'dbc'}`}
						ref={(node) => {
							this.dialogContent = node;
						}}
					>
						<Avatar id='cardMainIcon' className='flexAuto'>
							<ContactsIcon id='cardMainIconSize' />
						</Avatar>
						<h1 className='title center mg0'>{t('contact_us')}</h1>
						<h3 className='subtitle center mg0'>{t('contact_us_subtitle')}</h3>

						{success && <SmoothScrollToTop />}
						<CustomAlerts success={success} />
						<CustomAlerts error={error} />
						<CustomAlerts
							margin='1rem 0 0 0'
							info={termsNotAgreed}
							message={t(
								'agree_terms_service_privacy_policy_community_guidelines',
							)}
						/>

						{credentials.supportReportsMadeToday >= 30 && (
							<div>
								<CustomAlerts
									severity='warning'
									info={t('you_have_exceeded_daily_limit_action')}
								/>
								<CustomAlerts
									info={t('security_anti_spam_measures')}
									icon={<SecurityIcon />}
									margin='0'
								/>
							</div>
						)}

						{success ? (
							<div>
								<hr />
								<Grid container className='center mgt'>
									<Grid item xs={6} sm={6} md={6}>
										<div className='fpass'>
											{credentials.handle ? (
												<Link to='/feed' id='link'>
													<span className='text bold capitalize'>
														{t('home')}
													</span>
												</Link>
											) : (
												<Link to='/login' id='link'>
													<span className='text bold capitalize'>
														{t('login')}
													</span>
												</Link>
											)}
										</div>
									</Grid>
									<Grid item xs={6} sm={6} md={6} id='baseline'>
										<div className='signup'>
											{credentials.handle ? (
												<Link to={`/users/${credentials.handle}`} id='link'>
													<span className='text bold capitalize'>
														{t('profile')}
													</span>
												</Link>
											) : (
												<Link to='/signup' id='link'>
													<span className='text bold capitalize'>
														{t('signup')}
													</span>
												</Link>
											)}
										</div>
									</Grid>
								</Grid>
							</div>
						) : (
							<div>
								<p className='bold'>
									{t('report_bug_or_issue')}{' '}
									<Link to='/report' className='capitalize' id='link'>
										{t('click_here')}
									</Link>
								</p>
								<div style={dim(credentials.supportReportsMadeToday >= 30)}>
									<form noValidate onSubmit={this.submitForm}>
										<CustomInput
											variant='outlined'
											type='email'
											titleLabel={t('email')}
											placeholder={t('type_here')}
											name='email'
											inputId='email'
											autoComplete='email'
											onChange={this.handleChange}
											helperText={reportErrors.email}
											inputError={reportErrors.email ? true : false}
											inputValue={email}
											inputAdornmentPosition='end'
											required={true}
											onBlur={() => this.blur('email')}
											maxLength={320}
											fullWidth={true}
											deletable={() => this.setState({ email: '' })}
											isEmail={isEmail(email)}
											disabled={
												credentials.supportReportsMadeToday >= 30 ||
												(credentials.email && authenticated && emailVerified) ||
												loader
													? true
													: false
											}
										/>
										<label className='inputText mgt mgb3px capitalize'>
											{t('topic')}
										</label>
										<FormControl
											fullWidth
											disabled={
												credentials.supportReportsMadeToday >= 30 || loader
											}
										>
											<Select
												labelId='Selector'
												value={topic}
												onChange={this.handleTopicChange}
											>
												<MenuItem value='support'>{t('support')}</MenuItem>
												<MenuItem value='marketing'>{t('marketing')}</MenuItem>
												<MenuItem value='jobs'>{t('jobs')}</MenuItem>
												<MenuItem value='suggestions'>
													{t('suggestions')}
												</MenuItem>
												<MenuItem value='legal'>{t('legal')}</MenuItem>
												<MenuItem value='copyright'>{t('copyright')}</MenuItem>
												<MenuItem value='refunds'>{t('refunds')}</MenuItem>
											</Select>
										</FormControl>

										{topic === 'refunds' ? (
											<CustomInput
												variant='outlined'
												type='transactionId'
												titleLabel={t('transaction_id')}
												placeholder={t('type_here')}
												name='transactionId'
												inputId='transactionId'
												onChange={this.handleChange}
												helperText={reportErrors.transactionId}
												inputError={reportErrors.transactionId ? true : false}
												inputValue={transactionId}
												required
												fullWidth
												maxLength={100}
												disabled={
													credentials.supportReportsMadeToday >= 30 || loader
												}
											/>
										) : (
											<CustomInput
												variant='outlined'
												type='title'
												titleLabel={t('title')}
												placeholder={t('type_here')}
												name='title'
												inputId='title'
												onChange={this.handleChange}
												helperText={reportErrors.title}
												inputError={reportErrors.title ? true : false}
												inputValue={title}
												required
												fullWidth
												maxLength={150}
												disabled={
													credentials.supportReportsMadeToday >= 30 || loader
												}
											/>
										)}

										<CustomInput
											variant='outlined'
											titleLabel={
												topic === 'refunds' ? t('reason') : t('description')
											}
											placeholder={t('type_here')}
											name='body'
											inputId='body'
											onChange={this.handleChange}
											inputError={reportErrors.body ? true : false}
											inputValue={body}
											required
											fullWidth
											maxLength={1500}
											helperText={
												reportErrors.body
													? reportErrors.body
													: body.length > 500
													? `${body.length}/1500`
													: ''
											}
											minRows={3}
											multiline
											disabled={
												credentials.supportReportsMadeToday >= 30 || loader
											}
										/>
										{topic === 'refunds' && (
											<CustomInput
												variant='outlined'
												titleLabel={t('amount')}
												placeholder={t('type_here')}
												name='amount'
												inputId='amount'
												onChange={this.handleChange}
												helperText={reportErrors.amount}
												inputError={reportErrors.amount ? true : false}
												inputValue={amount}
												fullWidth
												maxLength={30}
												multiline
												disabled={
													credentials.supportReportsMadeToday >= 30 || loader
												}
											/>
										)}
										<CustomInput
											variant='outlined'
											titleLabel={t('name')}
											placeholder={t('type_here')}
											name='name'
											inputId='name'
											onChange={this.handleChange}
											inputValue={name}
											fullWidth
											maxLength={50}
											multiline
											disabled={
												credentials.supportReportsMadeToday >= 30 || loader
											}
										/>
										{!authenticated && !credentials.email && (
											<div className='termsCheckbox mgt'>
												<Checkbox
													checked={termsAgreed}
													required
													onClick={() => this.agreeTerms('terms')}
													style={{
														color: termsNotAgreed ? 'red' : undefined,
														transform: 'translate(0, -1px)',
													}}
													disabled={loader}
												/>
												<span className='mg0'>
													{t('read_and_agreed_to')}{' '}
													<Link
														to='/terms-of-service'
														className='new underlineHover pointer'
													>
														{t('terms_of_service')},
													</Link>{' '}
													<Link
														to='/privacy-policy'
														className='new underlineHover pointer'
													>
														{t('privacy_policy')},
													</Link>{' '}
													{t('and')}{' '}
													<Link
														to='/community-guidelines'
														className='new underlineHover pointer'
													>
														{t('community_guidelines')}.
													</Link>
												</span>
											</div>
										)}

										<CustomButton
											type='submit'
											variant='contained'
											btnText={t('send')}
											loading={loader}
											margin='2rem auto'
											id='customInfoButton'
											startIcon={<SendIcon />}
											disabled={credentials.supportReportsMadeToday >= 30}
										/>
										<hr />
										<hr />
										<p className='mg0'>
											{t('our_list_of')}{' '}
											<Link
												to='/contact-directories'
												id='link'
												className='bold'
											>
												{t('contact_directories')}.
											</Link>
										</p>
									</form>
								</div>
							</div>
						)}
					</div>
				</div>
				{!authenticated && (
					<CustomAlerts
						info={true}
						message={
							<div>
								<p className='center mg0 small'>
									{t('protected_google')}{' '}
									<a
										href='https://policies.google.com/privacy'
										rel='noopener noreferrer'
										target='_blank'
										className='bold link underlineHover'
									>
										{t('privacy_policy')}
									</a>{' '}
									{t('and')}{' '}
									<a
										href='https://policies.google.com/terms'
										rel='noopener noreferrer'
										target='_blank'
										className='bold link underlineHover'
									>
										{t('terms_of_service')}
									</a>{' '}
									{t('apply')}.
								</p>
							</div>
						}
						margin='0'
						icon={<SecurityIcon />}
					/>
				)}
				<Footer />
			</div>
		);
	}
}

Contact.propTypes = {
	user: PropTypes.object.isRequired,
	UI: PropTypes.object.isRequired,
	support: PropTypes.func.isRequired,
	clearReport: PropTypes.func.isRequired,
};

const mapActionsToProps = {
	support,
	clearReport,
};

const mapStateToProps = (state) => ({
	user: state.user,
	UI: state.UI,
});

export default connect(mapStateToProps, mapActionsToProps)(withRouter(Contact));
