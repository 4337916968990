import {
	Avatar,
	Checkbox,
	FormControl,
	FormControlLabel,
	Grid,
	MenuItem,
	Radio,
	Select,
} from '@mui/material';
import PropTypes from 'prop-types';
import { Component } from 'react';
import { Helmet } from 'react-helmet-async';
import {
	reportIssue,
	reportFeature,
	clearReport,
} from '../../redux/actions/userActions';
import { connect } from 'react-redux';
import CustomAlerts from '../SocialComponents/CustomAlerts';
import ReCAPTCHA from 'react-google-recaptcha-enterprise';
import { Link } from 'react-router-dom';
import Footer from '../Footer/Footer';
import CustomButton from '../SocialComponents/CustomButton';
import SendIcon from '@mui/icons-material/Send';
import CustomInput from '../SocialComponents/CustomInput';
import { withRouter } from '../../redux/withRouter';
import AnnouncementIcon from '@mui/icons-material/Announcement';
import AllInclusiveIcon from '@mui/icons-material/AllInclusive';
import SecurityIcon from '@mui/icons-material/Security';
import LightbulbIcon from '@mui/icons-material/Lightbulb';
import { dim, isEmail } from '../../redux/actions/dataActions';
import SmoothScrollToTop from '../../components/ScrollToTop/SmoothScrollToTop';
import { t } from 'i18next';

class Report extends Component {
	state = {
		emptyPriority: false,
		minorError: false,
		mediumError: false,
		majorError: false,
		device: 'computer',
		browser: 'chrome',
		brand: 'iphone',
		body: '',
		title: '',
		email: '',
		name: '',
		reportErrors: {},
		reportComponent: false,
		featureComponent: false,
		termsAgreed: false,
		termsNotAgreed: false,
	};
	componentDidMount() {
		if (
			this.props.location.pathname === '/report' ||
			this.props.params.boundaryId
		) {
			this.setState({ reportComponent: true });
		} else {
			this.setState({ featureComponent: true });
		}

		const userAgent = window.navigator.userAgent;

		let device = 'computer';
		let browser = 'chrome';
		let brand = 'iphone';
		let name = '';
		let email = '';

		// Detect device type and phone brand
		if (/Mobi|Android|iPhone/.test(userAgent)) {
			device = 'phone';
			if (userAgent.includes('Android')) {
				if (userAgent.includes('Samsung')) {
					brand = 'samsung';
				} else if (userAgent.includes('Motorola')) {
					brand = 'motorola';
				} else if (userAgent.includes('Xiaomi')) {
					brand = 'xiaomi';
				} else if (userAgent.includes('HUAWEI')) {
					brand = 'huawei';
				} else if (userAgent.includes('OnePlus')) {
					brand = 'oneplus';
				} else if (userAgent.includes('OPPO')) {
					brand = 'oppo';
				} else if (userAgent.includes('Pixel')) {
					brand = 'google';
				} else {
					brand = 'other';
				}
			} else if (userAgent.includes('iPhone')) {
				brand = 'iphone';
			}
		} else {
			device = 'computer';
		}

		if (userAgent.includes('Chrome') && !userAgent.includes('Edg')) {
			browser = 'chrome';
		} else if (userAgent.includes('Firefox')) {
			browser = 'firefox';
		} else if (
			userAgent.includes('Safari') &&
			!userAgent.includes('Chrome') &&
			!userAgent.includes('Edg')
		) {
			browser = 'safari';
		} else if (userAgent.includes('Edg')) {
			browser = 'edge';
		} else if (userAgent.includes('Trident') || userAgent.includes('MSIE')) {
			browser = 'explorer';
		} else if (userAgent.includes('Opera') || userAgent.includes('OPR')) {
			browser = 'opera';
		}

		if (this.props.user.authenticated && this.props.user.emailVerified) {
			email = this.props.user.credentials.email;
			if (this.props.user.credentials.name) {
				name = this.props.user.credentials.name;
			}
		}

		this.setState({
			browser,
			device,
			brand,
			name,
			email,
		});
	}

	static getDerivedStateFromProps(nextProps, state) {
		if (
			nextProps.user.authenticated &&
			nextProps.user.emailVerified &&
			nextProps.user.credentials.email &&
			state.email !== nextProps.user.credentials.email
		) {
			let update = {
				email: nextProps.user.credentials.email,
			};
			if (nextProps.user.credentials.name) {
				update.name = nextProps.user.credentials.name;
			}
			return update;
		}
		return null;
	}

	componentWillUnmount() {
		if (
			this.props.user.reportingIssueSuccess ||
			this.props.user.reportingFeatureSuccess ||
			this.props.user.reportingFeature ||
			this.props.user.errorReportingIssue ||
			this.props.user.errorReportingFeature
		) {
			this.props.clearReport();
		}
	}

	focus = (id) => {
		document.getElementById(id).focus();
	};

	blur = (id) => {
		document.getElementById(id).blur();
	};

	testEmpty = () => {
		let state = this.state;

		if (state.email.trim() === '' && !this.props.user.credentials.email) {
			this.focus('email');
			this.setState({
				reportErrors: { email: t('email_error_enter_address') },
			});
		} else if (
			isEmail(state.email) === false &&
			!this.props.user.credentials.email
		) {
			this.focus('email');
			this.setState({
				reportErrors: { email: t('email_error_enter_valid_address') },
			});
		} else if (
			state.reportComponent &&
			!state.majorError &&
			!state.mediumError &&
			!state.minorError
		) {
			this.setState({ emptyPriority: true });
			this.dialogContent.scrollIntoView({
				behavior: 'smooth',
				block: 'start',
			});
		} else if (state.title.trim() === '') {
			this.focus('title');
			this.setState({
				reportErrors: { title: t('please_enter_a_title') },
			});
		} else if (state.body.trim() === '') {
			this.focus('body');
			this.setState({
				reportErrors: { body: t('please_enter_a_description') },
			});
		} else if (!state.termsAgreed && !this.props.user.credentials.email) {
			this.setState({
				termsNotAgreed: true,
			});
		} else {
			this.setState({
				emptyPriority: false,
				reportErrors: {},
			});
			return true;
		}
	};

	submit = (value) => {
		let state = this.state;
		let formData = {
			title: state.title.trim(),
			body: state.body.trim(),
		};

		if (value) {
			formData.responseToken = value;
		}
		if (state.name.trim() !== '') {
			formData.name = state.name.trim();
		}
		const auth = this.props.user.authenticated && this.props.user.emailVerified;

		if (state.reportComponent) {
			formData.device = state.device;
			if (state.device === 'computer') {
				formData.browser = state.browser;
			} else {
				formData.brand = state.brand;
			}
			formData.priority = state.minorError
				? 'minor'
				: state.mediumError
				? 'medium'
				: state.majorError
				? 'major'
				: false;
			if (!auth) {
				formData.reporterEmail = state.email.toLowerCase();
			}
			if (this.props.params.boundaryId) {
				formData.boundaryId = this.props.params.boundaryId;
			}

			this.props.reportIssue(formData, auth);
		} else {
			formData.userId = this.props.user.credentials.userId;
			this.props.reportFeature(formData);
		}

		this.recaptcha.reset();
	};

	submitForm = (event) => {
		event.preventDefault();

		if (this.testEmpty()) {
			const auth =
				this.props.user.authenticated && this.props.user.emailVerified;

			if (auth) {
				this.submit();
			} else {
				this.recaptcha.execute();
			}
		} else {
			this.recaptcha.reset();
		}
	};

	handleChecked = (event) => {
		this.setState({
			minorError: false,
			mediumError: false,
			majorError: false,
			emptyPriority: false,
			[event.target.name]: event.target.checked,
		});
	};
	handleChange = (event) => {
		this.setState({
			[event.target.name]: event.target.value,
		});
		let state = this.state;
		if (
			(event.target.name === 'title' && state.reportErrors.title) ||
			(event.target.name === 'body' && state.reportErrors.body) ||
			(event.target.name === 'email' && state.reportErrors.email)
		) {
			this.setState({
				reportErrors: {},
			});
		}
	};

	agreeTerms = () => {
		if (this.state.termsNotAgreed) {
			this.setState({
				termsNotAgreed: false,
			});
		}
		this.setState((prevState) => ({ termsAgreed: !prevState.termsAgreed }));
	};

	render() {
		const {
			reportComponent,
			featureComponent,
			majorError,
			mediumError,
			minorError,
			device,
			browser,
			brand,
			body,
			reportErrors,
			title,
			name,
			email,
			emptyPriority,
			termsAgreed,
			termsNotAgreed,
		} = this.state;
		const {
			user: {
				authenticated,
				reportingIssue,
				reportingIssueSuccess,
				errorReportingIssue,
				reportingFeature,
				reportingFeatureSuccess,
				errorReportingFeature,
				credentials,
			},
			UI: { mode },
		} = this.props;

		const loader = reportComponent ? reportingIssue : reportingFeature;
		const success = reportComponent
			? reportingIssueSuccess
			: reportingFeatureSuccess;
		const error = reportComponent ? errorReportingIssue : errorReportingFeature;
		const disabled =
			credentials.issueReportsMadeToday >= 30 ||
			credentials.featureReportsMadeToday >= 30;

		return (
			<div>
				<Helmet>
					<title className='notranslate'>
						{reportComponent ? 'Report' : 'Feature'} | Goodbye App
					</title>
				</Helmet>
				<ReCAPTCHA
					sitekey={process.env.REACT_APP_CAPTCHA_SITE_KEY}
					ref={(ref) => (this.recaptcha = ref)}
					theme='dark'
					onChange={this.submit}
					size='invisible'
				/>

				<div id='backgroundAuth' className='pd2Media'>
					<div
						className={`card pd2b1Media ${mode === 'dark' && 'dbc'}`}
						ref={(node) => {
							this.dialogContent = node;
						}}
					>
						<Avatar id='cardMainIcon' className='flexAuto'>
							{reportComponent ? (
								<AnnouncementIcon id='cardMainIconSize' className='white' />
							) : (
								<LightbulbIcon id='cardMainIconSize' className='white' />
							)}
						</Avatar>
						<h1 className='title center mg0'>
							{reportComponent ? t('report_issue') : t('feature_request')}
						</h1>
						<h3 className='subtitle center mg0'>
							{reportComponent ? t('report_subtitle') : t('feature_subtitle')}
						</h3>

						<CustomAlerts error={error} />
						{(credentials.featureReportsMadeToday >= 30 ||
							credentials.issueReportsMadeToday >= 30) && (
							<div>
								<CustomAlerts
									severity='warning'
									info={t('you_have_exceeded_daily_limit_action')}
								/>
								<CustomAlerts
									info={t('security_anti_spam_measures')}
									icon={<SecurityIcon />}
									margin='0'
								/>
							</div>
						)}

						<CustomAlerts
							margin='1rem 0 0 0'
							info={termsNotAgreed}
							message={t(
								'agree_terms_service_privacy_policy_community_guidelines',
							)}
						/>

						<p className='bold'>
							{t('need_support')}{' '}
							<Link to='/contact' className='capitalize' id='link'>
								{t('click_here')}
							</Link>
						</p>

						{(reportComponent || featureComponent) && !success ? (
							<div style={dim(disabled)}>
								<form noValidate onSubmit={this.submitForm}>
									{reportComponent && (
										<CustomInput
											variant='outlined'
											type='email'
											titleLabel={t('email')}
											placeholder={t('type_here')}
											name='email'
											inputId='email'
											autoComplete='email'
											onChange={this.handleChange}
											helperText={reportErrors.email}
											inputError={reportErrors.email ? true : false}
											inputValue={email}
											inputAdornmentPosition='end'
											required={true}
											onBlur={() => this.blur('email')}
											maxLength={320}
											fullWidth={true}
											deletable={() => this.setState({ email: '' })}
											isEmail={isEmail(email)}
											disabled={
												loader || disabled || credentials.email ? true : false
											}
										/>
									)}
									{reportComponent && (
										<div>
											<p className='inputText mg0 mgt'>
												{t('check_one_severity_level')}
												{emptyPriority && (
													<span className='errorSpan mgl6px'>
														- {t('required')}
													</span>
												)}
												:
											</p>
											<div className='reportCheckboxes'>
												<FormControlLabel
													disabled={loader || disabled}
													value='end'
													id='mgYOnlyMedia'
													control={
														<Radio
															onChange={this.handleChecked}
															name='majorError'
															checked={majorError}
															sx={{
																color: 'red',
																'&.Mui-checked': {
																	color: 'red',
																},
															}}
														/>
													}
													label={t('major_severity')}
													labelPlacement='end'
												/>
												<FormControlLabel
													disabled={loader || disabled}
													value='end'
													id='mgbOnlyMedia'
													control={
														<Radio
															onChange={this.handleChecked}
															name='mediumError'
															checked={mediumError}
															sx={{
																color: 'orange',
																'&.Mui-checked': {
																	color: 'orange',
																},
															}}
														/>
													}
													label={t('moderate_severity')}
													labelPlacement='end'
												/>
												<FormControlLabel
													disabled={loader || disabled}
													value='end'
													control={
														<Radio
															onChange={this.handleChecked}
															name='minorError'
															checked={minorError}
															sx={{
																color: 'green',
																'&.Mui-checked': {
																	color: 'green',
																},
															}}
														/>
													}
													label={t('minor_severity')}
													labelPlacement='end'
												/>
											</div>
											<label className='inputText mgt mgb3px capitalize'>
												{t('device')}
											</label>
											<FormControl fullWidth disabled={loader || disabled}>
												<Select
													labelId='Device Selector'
													value={device}
													name='device'
													onChange={this.handleChange}
												>
													<MenuItem value='computer'>{t('computer')}</MenuItem>
													<MenuItem value='phone'>{t('phone')}</MenuItem>
												</Select>
											</FormControl>

											<label className='inputText mgt mgb3px capitalize'>
												{device === 'computer' ? t('browser') : t('brand')}
											</label>
											{device === 'computer' ? (
												<FormControl fullWidth disabled={loader || disabled}>
													<Select
														labelId='Browser Selector'
														value={browser}
														name='browser'
														onChange={this.handleChange}
													>
														<MenuItem value='chrome'>Google Chrome</MenuItem>
														<MenuItem value='edge'>Microsoft Edge</MenuItem>
														<MenuItem value='explorer'>
															Internet Explorer
														</MenuItem>
														<MenuItem value='safari'>Safari</MenuItem>
														<MenuItem value='firefox'>Firefox</MenuItem>
														<MenuItem value='opera'>Opera</MenuItem>
														<MenuItem value='other'>Other</MenuItem>
													</Select>
												</FormControl>
											) : (
												<FormControl fullWidth disabled={loader || disabled}>
													<Select
														labelId='Brand Selector'
														value={brand}
														name='brand'
														onChange={this.handleChange}
													>
														<MenuItem value='iphone'>Iphone</MenuItem>
														<MenuItem value='samsung'>Samsung</MenuItem>
														<MenuItem value='xiaomi'>Xiaomi</MenuItem>
														<MenuItem value='motorola'>Motorola</MenuItem>
														<MenuItem value='oppo'>OPPO</MenuItem>
														<MenuItem value='google'>Google</MenuItem>
														<MenuItem value='onePlus'>One Plus</MenuItem>
														<MenuItem value='huawei'>Huawei</MenuItem>
														<MenuItem value='other'>Other</MenuItem>
													</Select>
												</FormControl>
											)}
										</div>
									)}
									<CustomInput
										variant='outlined'
										type='title'
										titleLabel={t('title')}
										placeholder={t('type_here')}
										name='title'
										inputId='title'
										onChange={this.handleChange}
										helperText={reportErrors.title}
										inputError={reportErrors.title ? true : false}
										inputValue={title}
										required
										fullWidth
										maxLength={150}
										disabled={loader || disabled}
									/>
									<CustomInput
										variant='outlined'
										titleLabel={`${t('description')}${
											reportComponent ? ` - ${t('steps_to_reproduce')}` : ''
										}`}
										placeholder={t('type_here')}
										name='body'
										inputId='body'
										onChange={this.handleChange}
										inputError={reportErrors.body ? true : false}
										inputValue={body}
										required
										fullWidth
										maxLength={1500}
										helperText={
											reportErrors.body
												? reportErrors.body
												: body.length >= 500
												? `${body.length}/1500`
												: ''
										}
										minRows={3}
										multiline
										disabled={loader || disabled}
									/>
									<CustomInput
										variant='outlined'
										titleLabel={t('name')}
										placeholder={t('type_here')}
										name='name'
										inputId='name'
										onChange={this.handleChange}
										inputValue={name}
										fullWidth
										maxLength={50}
										multiline
										disabled={loader || disabled}
									/>
									{!authenticated && !credentials.email && (
										<div className='termsCheckbox mgt'>
											<Checkbox
												checked={termsAgreed}
												required
												onClick={() => this.agreeTerms('terms')}
												style={{
													color: termsNotAgreed ? 'red' : undefined,
													transform: 'translate(0, -1px)',
												}}
												disabled={loader}
											/>
											<span className='mg0'>
												{t('read_and_agreed_to')}{' '}
												<Link
													to='/terms-of-service'
													className='new underlineHover pointer'
												>
													{t('terms_of_service')},
												</Link>{' '}
												<Link
													to='/privacy-policy'
													className='new underlineHover pointer'
												>
													{t('privacy_policy')},
												</Link>{' '}
												{t('and')}{' '}
												<Link
													to='/community-guidelines'
													className='new underlineHover pointer'
												>
													{t('community_guidelines')}.
												</Link>
											</span>
										</div>
									)}
									<CustomButton
										type='submit'
										variant='contained'
										btnText={t('send')}
										loading={loader}
										margin='1.5rem auto 1rem'
										id='customInfoButton'
										startIcon={<SendIcon />}
										disabled={loader || disabled}
									/>
								</form>
							</div>
						) : (
							<div>
								{success && <SmoothScrollToTop />}

								<CustomAlerts success={success} />
								<CustomAlerts
									info={success && reportComponent}
									icon={<AllInclusiveIcon />}
									margin='0 0 1rem 0'
									message={t('feature_report_success')}
								/>
								<Grid container className='center'>
									<Grid item xs={6} sm={6} md={6}>
										<div className='fpass'>
											{credentials.handle ? (
												<Link to='/feed' id='link'>
													<span className='text bold'>{t('home')}</span>
												</Link>
											) : (
												<Link to='/login' id='link'>
													<span className='text bold'>{t('login')}</span>
												</Link>
											)}
										</div>
									</Grid>
									<Grid item xs={6} sm={6} md={6} id='baseline'>
										<div className='signup'>
											{credentials.handle ? (
												<Link to={`/users/${credentials.handle}`} id='link'>
													<span className='text bold'>{t('profile')}</span>
												</Link>
											) : (
												<Link to='/signup' id='link'>
													<span className='text bold'>{t('signup')}</span>
												</Link>
											)}
										</div>
									</Grid>
								</Grid>
							</div>
						)}
					</div>
				</div>
				{!authenticated && (
					<CustomAlerts
						info={true}
						message={
							<div>
								<p className='center mg0 small'>
									{t('protected_google')}{' '}
									<a
										href='https://policies.google.com/privacy'
										rel='noopener noreferrer'
										target='_blank'
										className='bold link underlineHover'
									>
										{t('privacy_policy')}
									</a>{' '}
									{t('and')}{' '}
									<a
										href='https://policies.google.com/terms'
										rel='noopener noreferrer'
										target='_blank'
										className='bold link underlineHover'
									>
										{t('terms_of_service')}
									</a>{' '}
									{t('apply')}.
								</p>
							</div>
						}
						margin='0'
						icon={<SecurityIcon />}
					/>
				)}
				<Footer />
			</div>
		);
	}
}

Report.propTypes = {
	user: PropTypes.object.isRequired,
	UI: PropTypes.object.isRequired,
	reportIssue: PropTypes.func.isRequired,
	reportFeature: PropTypes.func.isRequired,
	clearReport: PropTypes.func.isRequired,
};

const mapActionsToProps = {
	reportIssue,
	reportFeature,
	clearReport,
};

const mapStateToProps = (state) => ({
	user: state.user,
	UI: state.UI,
});

export default connect(mapStateToProps, mapActionsToProps)(withRouter(Report));
