import { Helmet } from 'react-helmet-async';
import Footer from '../../components/Footer/Footer';
import { Avatar, IconButton } from '@mui/material';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import PolicyIcon from '@mui/icons-material/Policy';
import { useSelector } from 'react-redux';

function ChildSafetyPolicy() {
	const mode = useSelector((state) => state.UI.mode);
	return (
		<div>
			<Helmet>
				<title>Child Safety Standards Policy - Goodbye App</title>
			</Helmet>
			<div className='terms__Container pd2Media'>
				<div className={`card pd2Media ${mode === 'dark' && 'dbc'}`}>
					<Avatar id='cardMainIcon' className='flexAuto'>
						<PolicyIcon id='cardMainIconSize' />
					</Avatar>
					<h1 className='title center mgbOnly'>
						Child Safety Standards Policy
					</h1>
					<h2>Overview</h2>
					<b>Effective January 1, 2025</b>

					<p>
						At Goodbye App, we are committed to creating a safe and supportive
						environment for all users, including children and minors. This Child
						Safety Standards Policy outlines our approach to protecting young
						users and ensuring compliance with applicable laws and regulations
						regarding child safety.
					</p>

					<h2>Age Restrictions</h2>
					<ul className='text'>
						<li>
							Goodbye App is designed for users aged 18 and older. Users under
							18 are not permitted to create accounts or use the platform.
						</li>
						<li>
							We implement robust age verification processes during the account
							registration phase to help prevent unauthorized access by minors.
						</li>
					</ul>
					<h2>Content Moderation</h2>
					<ul className='text'>
						<li>
							Inappropriate content flagged by users or detected through
							automated systems is reviewed promptly by our moderation team.
						</li>
						<li>
							All user-generated content is subject to moderation to ensure
							compliance with our community guidelines and child safety
							standards.
						</li>
						<li>
							Content that promotes, depicts, or glorifies violence, abuse,
							exploitation, or any form of harm to minors is strictly prohibited
							and will be removed immediately.
						</li>
					</ul>
					<h2>Reporting and Enforcement</h2>
					<ul className='text'>
						<li>
							Accounts found violating child safety policies will be suspended
							or permanently banned, and, when required, reported to appropriate
							legal authorities.
						</li>
						<li>
							Users can report any suspicious or harmful activity involving
							minors through an accessible and anonymous reporting system.
						</li>
						<li>
							Reports are prioritized and addressed by a dedicated team trained
							in handling sensitive child safety concerns.
						</li>
					</ul>
					<h2>Privacy and Data Protection</h2>
					<ul className='text'>
						<li>
							Goodbye App complies with the Children’s Online Privacy Protection
							Act (COPPA), the General Data Protection Regulation (GDPR), and
							other relevant regulations.
						</li>
						<li>
							We do not knowingly collect, store, or process personal
							information from users under 18 years old.
						</li>
						<li>
							All user data is handled with the highest standards of security to
							protect against unauthorized access and misuse.
						</li>
					</ul>
					<h2>Educational Resources</h2>
					<ul className='text'>
						<li>
							We provide resources to educate parents and guardians about online
							safety and digital well-being.
						</li>
						<li>
							The platform includes guidelines to help users understand risks
							and how to protect themselves online.
						</li>
					</ul>
					<h2>Third-Party Integrations and Advertising</h2>
					<ul className='text'>
						<li>
							Any third-party services integrated with Goodbye App must adhere
							to our child safety standards.
						</li>
					</ul>
					<h2>Regular Policy Reviews</h2>
					<ul className='text'>
						<li>
							This policy is reviewed regularly to ensure it remains up-to-date
							with evolving laws, regulations, and best practices related to
							child safety.
						</li>
						<li>
							Feedback from users and stakeholders is incorporated into periodic
							updates to improve the policy.
						</li>
					</ul>

					<hr />

					<p>
						Contact Information If you have questions, concerns, or suggestions
						regarding this policy, please contact us at:{' '}
						<b className='italic'>support@goodbyeapp.com</b>
					</p>
					<p className='mg0'>
						We are committed to promptly addressing any inquiries or issues
						related to child safety on our platform.
					</p>
				</div>
			</div>
			<Footer />
		</div>
	);
}

export default ChildSafetyPolicy;
