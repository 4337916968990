import { Link } from 'react-router-dom';
import CopyrightProceedings from '../LEGAL/CopyrightProceedings';

export default function Tos({ unitedStates }) {
	return (
		<div>
			<h2>Who May Use the Services</h2>
			<p>
				You may use the Services only if you agree to form a binding contract
				with Goodbye App and are not a person prohibited from receiving services
				under the laws of the applicable jurisdiction. To use the Services, you
				must meet the following criteria:
			</p>
			<ul className='text'>
				<li>
					<b>Age Requirements:</b> You must be at least 18 years old to use our
					Services. You must also be old enough to consent to the processing of
					your personal data in your country (in some countries we may allow
					your parent or guardian to do so on your behalf). See our{' '}
					<Link to='/child-safety-standards-policy' id='link' className='bold'>
						Child Safety Standards Policy.
					</Link>
				</li>
				<li>
					<b>Legal Compliance:</b> You must not be barred from receiving our
					Services under the laws of the country in which you reside.
				</li>
				<li>
					<b>Entity Representation:</b> If you are using the Services on behalf
					of a company, organization, government, or other legal entity, you
					must have the authority to bind that entity to these Terms and you
					agree to these Terms on behalf of that entity.
				</li>
			</ul>
			<p className='bold'>
				By using our Services, you represent and warrant that you meet these
				criteria.
			</p>
			<h2>Privacy</h2>
			<p>
				Our{' '}
				<Link to='/privacy-policy' id='link' className='bold'>
					Privacy Policy
				</Link>{' '}
				<Link to='/privacy-policy' id='link' className='bold'>
					(https://goodbyeapp/privacy-policy)
				</Link>{' '}
				explains how we handle the information you provide to us when you use
				our Services. By using the Services, you acknowledge and consent to the
				collection and use of your information as outlined in the{' '}
				<Link to='/privacy-policy' id='link' className='bold'>
					Privacy Policy
				</Link>
				. This includes the transfer of your information to the United States,
				and other countries for storage, processing, and use by{' '}
				<span className='notranslate'> Goodbye App </span> and its affiliates.
			</p>
			<h2>Your Responsibility and Use of Content</h2>
			<p>
				You are responsible for your use of the Services and for any Content you
				provide, ensuring compliance with applicable laws, rules, and
				regulations. Only share Content that you are comfortable sharing with
				others.
			</p>
			<p>
				Any use or reliance on Content or materials posted via the Services or
				obtained through the Services is at your own risk.{' '}
				<span className='notranslate'> Goodbye App </span> does not endorse,
				support, represent, or guarantee the completeness, truthfulness,
				accuracy, or reliability of any Content or communications posted via the
				Services, nor does it endorse any opinions expressed via the Services.
			</p>
			<p>
				By using the Services, you understand that you may be exposed to Content
				that might be offensive, harmful, inaccurate, or otherwise
				inappropriate, or in some cases, mislabeled or deceptive. All Content is
				the sole responsibility of the person who originated it.{' '}
				<span className='notranslate'> Goodbye App </span> may not monitor or
				control the Content posted via the Services and cannot take
				responsibility for such Content.
			</p>
			<h2>Content Removal and Reporting Violations</h2>
			<p>
				We reserve the right to remove Content that violates the User Agreement,
				including, for example, copyright or trademark violations,
				impersonation, unlawful conduct, or harassment. Information regarding
				specific policies and the process for reporting or appealing violations
				can be found in our{' '}
				<Link to='/community-guidelines' id='link' className='bold'>
					Community Guidelines.
				</Link>
			</p>
			<p>
				Depending on the nature of the infringement and the specific
				circumstances, users may be able to provide additional context or
				evidence to support their appeal.{' '}
				<Link to='/submitting-appeals' id='link' className='bold'>
					Learn more.
				</Link>
			</p>
			<p>
				If you believe that your Content has been copied in a way that
				constitutes copyright infringement you can submit a report:
			</p>
			<CopyrightProceedings />
			<p>
				For more information regarding copyright, view our{' '}
				<Link to='/copyright-policy' id='link' className='bold'>
					copyright policy
				</Link>
				.
			</p>
			<h2>Your Rights and Grant of Rights in the Content</h2>
			<p>
				You maintain ownership of any Content you upload, share, or showcase on
				our Services. Your content, including audio, photos, and videos, remains
				yours.
			</p>
			<p>
				By uploading, sharing, or showcasing Content on our Services, you grant
				us a worldwide, non-exclusive, royalty-free license (with the right to
				sublicense) to use, copy, reproduce, process, adapt, modify, publish,
				transmit, display, and distribute such Content through any media or
				distribution methods (whether known now or developed in the future).
				This license allows us to share your Content with the world and permits
				others to do the same. You agree that this license also enables Goodbye
				App to provide, promote, and enhance the Services, and to make your
				Content available to other companies, organizations, or individuals for
				syndication, broadcast, distribution, promotion, or publication on other
				media and platforms, in accordance with our terms and conditions for
				Content use. These additional uses by{' '}
				<span className='notranslate'> Goodbye App </span> or other entities may
				occur without compensating you for the Content you upload, share,
				transmit, or otherwise provide through our Services.
			</p>
			<p>
				<span className='notranslate'> Goodbye App </span> maintains a dynamic
				set of guidelines governing how partners in our ecosystem can interact
				with your Content on the platform. These guidelines are designed to
				foster an open ecosystem while prioritizing your rights. You acknowledge
				that we may modify or adjust your Content as part of its distribution,
				syndication, publication, or broadcasting by us, our partners, and/or
				make necessary alterations to adapt the Content for different media
				formats.
			</p>
			<p>
				You confirm and guarantee that you possess, or have obtained, all
				rights, licenses, consents, permissions, power, and/or authority
				required to grant the rights delineated herein for any Content you
				upload, post, or showcase on or through our Services. You agree that
				such Content will not infringe upon copyright or other proprietary
				rights, unless you possess the necessary permissions or are otherwise
				legally entitled to post the material and provide{' '}
				<span className='notranslate'> Goodbye App </span> with the described
				license.
			</p>
			<h2>Using the Services</h2>
			<p>
				Please review the Rules and Policies of{' '}
				<span className='notranslate'> Goodbye App </span> (and, for specific
				guidelines related to certain features, refer to the corresponding{' '}
				<Link to='/community-guidelines' id='link' className='bold'>
					Community Guidelines
				</Link>
				, which form part of the User Agreement and outline the prohibited
				actions and behaviors on the platform. You are permitted to use our
				Services only in accordance with these Terms and all relevant laws,
				rules, and regulations.
			</p>
			<p>
				Our platform undergoes continuous evolution. Consequently, the Services
				may undergo changes periodically, at our discretion. We reserve the
				right to cease (permanently or temporarily) providing certain Services
				or features within the Services, either to you individually or to users
				collectively. Additionally, we may establish usage and storage
				limitations at our sole discretion at any given time. We also reserve
				the right to remove or decline the distribution of any Content on the
				platform, suspend or terminate user accounts, and reclaim usernames
				without incurring any liability towards you.
			</p>
			<p>
				In consideration for <span className='notranslate'> Goodbye App </span>{' '}
				granting you access to and use of the Services, you agree that{' '}
				<span className='notranslate'> Goodbye App </span> and its third-party
				providers and partners may place advertising on the Services or in
				connection with the display of Content or information from the Services
				whether submitted by you or others. You also agree not to misuse our
				Services, for example, by interfering with them or accessing them using
				a method other than the interface and the instructions that we provide.
				You may not do any of the following while accessing or using the
				Services: (i) access, tamper with, or use non-public areas of the
				Services, <span className='notranslate'> Goodbye App </span> computer
				systems, or the technical delivery systems of{' '}
				<span className='notranslate'> Goodbye App </span> providers; (ii)
				probe, scan, or test the vulnerability of any system or network or
				breach or circumvent any security or authentication measures; (iii)
				access or search or attempt to access or search the Services by any
				means (automated or otherwise) other than through our currently
				available, published interfaces that are provided by{' '}
				<span className='notranslate'> Goodbye App </span> (and only pursuant to
				the applicable terms and conditions), unless you have been specifically
				allowed to do so in a separate agreement with Goodbye App (NOTE:
				crawling the Services is permissible if done in accordance with the
				provisions of the robots.txt file, however, scraping the Services
				without the prior consent of{' '}
				<span className='notranslate'> Goodbye App </span> is expressly
				prohibited); (iv) forge any TCP/IP packet header or any part of the
				header information in any email or posting, or in any way use the
				Services to send altered, deceptive or false source-identifying
				information; or (v) interfere with, or disrupt, (or attempt to do so),
				the access of any user, host or network, including, without limitation,
				sending a virus, overloading, flooding, spamming, mail-bombing the
				Services, or by scripting the creation of Content in such a manner as to
				interfere with or create an undue burden on the Services. We also
				reserve the right to access, read, preserve, and disclose any
				information as we reasonably believe is necessary to (i) satisfy any
				applicable law, regulation, legal process or governmental request, (ii)
				enforce the Terms, including investigation of potential violations
				hereof, (iii) detect, prevent, or otherwise address fraud, security or
				technical issues, (iv) respond to user support requests, or (v) protect
				the rights, property or safety of{' '}
				<span className='notranslate'> Goodbye App,</span> its users and the
				public.
				<span className='notranslate'> Goodbye App </span> does not disclose
				personally-identifying information to third parties except in accordance
				with our Privacy Policy.
			</p>
			<p>
				To reproduce, modify, create derivative works, distribute, sell,
				transfer, publicly display, publicly perform, transmit, or use the
				Services or Content on the Services in any manner, you must adhere to
				the interfaces and instructions we provide. This is applicable unless
				expressly permitted through
				<span className='notranslate'> Goodbye App </span> Services and in
				accordance with these Terms.
			</p>
			<h2>Your Account</h2>
			<p>
				Creating an account may be necessary to access certain features of our
				Services. It's important to protect your account by using a strong
				password and restricting its use solely to this account. Failure to
				comply with these precautions may result in loss or damage for which we
				cannot be held liable.
			</p>
			<p>
				You have significant control over the communications you receive from
				our Services. However, certain communications, such as service
				announcements and administrative messages, may be necessary and are
				integral to the functioning of the Services and your account.
				Consequently, you may not have the option to opt-out of receiving these
				essential communications.
			</p>
			<h2>Your License to Use the Services</h2>
			<p>
				<span className='notranslate'>Goodbye App </span> grants you a personal,
				worldwide, royalty-free, non-assignable, and non-exclusive license to
				utilize the software provided to you as part of the Services. This
				license is specifically designed to allow you to access and enjoy the
				benefits of the Services as provided by
				<span className='notranslate'> Goodbye App,</span> as permitted by the
				Terms.
			</p>
			<p>
				The Services are safeguarded by copyright, trademark, and other laws in
				both the United States and other countries worldwide. The Terms do not
				grant you the right to use
				<span className='notranslate'> Goodbye App </span> name or any{' '}
				<span className='notranslate'> Goodbye App </span>
				trademarks, logos, domain names, or other distinct brand features.
				Ownership, title, and interest in and to the Services (excluding Content
				provided by users) belong solely to
				<span className='notranslate'> Goodbye App </span> and its licensors.
			</p>
			<p>
				Any feedback, comments, or suggestions you offer regarding{' '}
				<span className='notranslate'>Goodbye App </span> or the Services are
				voluntary.
				<span className='notranslate'> Goodbye App </span> is free to utilize
				such feedback, comments, or suggestions as it deems appropriate, without
				any obligation to you.
			</p>
			<h2>Ending These Terms</h2>
			<p>
				You have the option to terminate your legal agreement with the Goodbye
				App at any time by deleting your account and ceasing to use the
				Services. Visit{' '}
				<Link to='/delete-account' id='link' className='bold'>
					Delete Account
				</Link>{' '}
				for detailed instructions on deleting your account. Additionally, refer
				to the Privacy Policy for information on the handling of your data after
				deactivation.
			</p>
			<p>
				We may suspend or terminate your account or stop providing you with all
				or part of the Services at any time for any reason, including, but not
				limited to, if we reasonably believe: (i) you have violated these Terms
				or <span className='notranslate'> Goodbye App </span>{' '}
				<Link to='/community-guidelines' id='link' className='bold'>
					Community Guidelines
				</Link>
				, (ii) you create risk or possible legal exposure for us, (iii) your
				account has been inactive for an extended period, or (iv) your account
				should be removed due to unlawful conduct, (v) our provision of the
				Services to you is no longer commercially viable.
			</p>
			<p>
				We will make reasonable efforts to notify you via the email address
				associated with your account or the next time you attempt to access your
				account, depending on the circumstances.
			</p>
			<p>
				In all such cases, these Terms shall terminate, including your license
				to use the Services, except that the following sections shall continue
				to apply: II, III, V, and VI.
			</p>
			{unitedStates ? (
				<div>
					<h2>Disclaimers and Limitations of Liability</h2>
					<p className='bold'>The Services are Available "AS-IS"</p>
					<p>
						Your access to and use of the Services or any Content is at your own
						risk. You understand and agree that the Services are provided to you
						on an “AS IS” and “AS AVAILABLE” basis. The term "
						<span className='notranslate'> Goodbye App </span>
						Entities" refers to{' '}
						<span className='notranslate'> Goodbye App,</span> its parent
						companies, affiliates, related companies, officers, directors,
						employees, agents, representatives, partners, and licensors.
					</p>
					<p>
						Without limiting the foregoing, to the maximum extent permitted
						under applicable law, THE{' '}
						<span className='notranslate'> GOODBYE APP </span> ENTITIES DISCLAIM
						ALL WARRANTIES AND CONDITIONS, WHETHER EXPRESS OR IMPLIED, INCLUDING
						WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, AND
						NON-INFRINGEMENT. The{' '}
						<span className='notranslate'> Goodbye App </span> Entities make no
						warranty or representation and disclaim all responsibility and
						liability for:
					</p>
					<ol className='text' type='i'>
						<li>
							the completeness, accuracy, availability, timeliness, security, or
							reliability of the Services or any Content;
						</li>
						<li>
							any harm to your computer system, loss of data, or other harm that
							results from your access to or use of the Services or any Content;
						</li>
						<li>
							the deletion of, or the failure to store or to transmit, any
							Content and other communications maintained by the Services; and
						</li>
						<li>
							whether the Services will meet your requirements or be available
							on an uninterrupted, secure, or error-free basis.
						</li>
					</ol>
					<h2>Limitation of Liability</h2>
					<p>
						TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, THE{' '}
						<span className='notranslate'> GOODBYE APP </span>
						ENTITIES SHALL NOT BE LIABLE FOR ANY INDIRECT, INCIDENTAL, SPECIAL,
						CONSEQUENTIAL, OR PUNITIVE DAMAGES, OR ANY LOSS OF PROFITS OR
						REVENUES, WHETHER INCURRED DIRECTLY OR INDIRECTLY, OR ANY LOSS OF
						DATA, USE, GOODWILL, OR OTHER INTANGIBLE LOSSES, RESULTING FROM (i)
						YOUR ACCESS TO OR USE OF OR INABILITY TO ACCESS OR USE THE SERVICES;
						(ii) ANY CONDUCT OR CONTENT OF ANY THIRD PARTY ON THE SERVICES,
						INCLUDING WITHOUT LIMITATION, ANY DEFAMATORY, OFFENSIVE, OR ILLEGAL
						CONDUCT OF OTHER USERS OR THIRD PARTIES; (iii) ANY CONTENT OBTAINED
						FROM THE SERVICES; OR (iv) UNAUTHORIZED ACCESS, USE, OR ALTERATION
						OF YOUR TRANSMISSIONS OR CONTENT.
					</p>
					<p>
						IN NO EVENT SHALL THE AGGREGATE LIABILITY OF THE{' '}
						<span className='notranslate'> GOODBYE APP </span>
						ENTITIES EXCEED THE GREATER OF ONE HUNDRED U.S. DOLLARS (U.S.
						$100.00) OR THE AMOUNT YOU PAID{' '}
						<span className='notranslate'> GOODBYE APP </span>, IF ANY, IN THE
						PAST SIX MONTHS FOR THE SERVICES GIVING RISE TO THE CLAIM. THE
						LIMITATIONS OF THIS SUBSECTION SHALL APPLY TO ANY THEORY OF
						LIABILITY, WHETHER BASED ON WARRANTY, CONTRACT, STATUTE, TORT
						(INCLUDING NEGLIGENCE) OR OTHERWISE, AND WHETHER OR NOT THE{' '}
						<span className='notranslate'> GOODBYE APP </span> ENTITIES HAVE
						BEEN INFORMED OF THE POSSIBILITY OF ANY SUCH DAMAGE, AND EVEN IF A
						REMEDY SET FORTH HEREIN IS FOUND TO HAVE FAILED OF ITS ESSENTIAL
						PURPOSE.
					</p>
				</div>
			) : (
				<div>
					<h2>Limitations of Liability</h2>

					<p>
						By using the Services you agree that{' '}
						<span className='notranslate'> Goodbye App,</span> its parents,
						affiliates, related companies, officers, directors, employees,
						agents representatives, partners and licensors, liability is limited
						to the maximum extent permissible in your country of residence.
					</p>
				</div>
			)}

			<h2>General</h2>
			<p>
				We may revise these Terms from time to time. The changes will not be
				retroactive, and the most current version of the Terms, which will
				always be at{' '}
				<a
					href='https://goodbyeapp.com/terms-of-service'
					rel='noreferrer'
					target='_blank'
					id='link'
					className='link underlineHover bold breakWord'
				>
					goodbyeapp.com/terms-of-service
				</a>
				, will govern our relationship with you.
				{unitedStates
					? 'We will try to notify you of material revisions, '
					: 'Other than for changes addressing new functions or made for legal reasons, we will notify you 15 days in advance of making effective changes to these Terms that impact the rights or obligations of any party to these Terms, '}
				for example via a service notification or an email to the email
				associated with your account. By continuing to access or use the
				Services after those revisions become effective, you agree to be bound
				by the revised Terms of Use, Privacy Policy, Cookie Policy and Community
				Guidelines.
			</p>
			{unitedStates && (
				<div>
					<p>
						The laws of the State of Delaware, excluding its choice of law
						provisions, will govern these Terms and any dispute that arises
						between you and <span className='notranslate'> Goodbye App </span>.
						All disputes related to these Terms or the Services will be brought
						solely in the federal or state courts located in the U.S. District
						Court for the District of Delaware, United States, and you consent
						to personal jurisdiction and waive any objection as to inconvenient
						forum.
					</p>

					<p className='mgtOnly'>
						If you are a federal, state, or local government entity in the
						United States using the Services in your official capacity and
						legally unable to accept the controlling law, jurisdiction, or venue
						clauses above, then those clauses do not apply to you. For such U.S.
						federal government entities, these Terms and any action related
						thereto will be governed by the laws of the United States of America
						(without reference to conflict of laws) and, in the absence of
						federal law and to the extent permitted under federal law, the laws
						of the State of Delaware (excluding choice of law).
					</p>
				</div>
			)}
			<p>
				Without prejudice to the foregoing, you agree that, in its sole
				discretion, <span className='notranslate'> Goodbye App </span>{' '}
				Corporation may also bring any claim we have against you related to
				efforts to abuse, interfere, or engage with our products in unauthorized
				ways in the country in which you reside that has jurisdiction over the
				claim.
			</p>
			<p>
				In the event that any provision of these Terms is held to be invalid or
				unenforceable, then that provision will be limited or eliminated to the
				minimum extent necessary, and the remaining provisions of these Terms
				will remain in full force and effect. Goodbye App’s failure to enforce
				any right or provision of these Terms will not be deemed a waiver of
				such right or provision.
			</p>
			<p>
				These Terms are an agreement between you and{' '}
				<span className='notranslate'> Goodbye App,</span> Corporation, 928 21ST
				ST APT 1 SANTA MONICA, CA 90403 U.S.A. If you have any questions about
				these Terms, please{' '}
				<Link to='/contact' id='link' className='bold'>
					contact us
				</Link>
				.
			</p>
		</div>
	);
}
